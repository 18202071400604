@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

:root {
  tab-size: 4;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

* {
  font-family: Inter, sans-serif;
}

blockquote {
  quotes: "“""”";
  margin: 1.5em 10px;
  padding: .5em 10px;
}

blockquote.before-quote:before {
  --tw-text-opacity: 1;
  color: rgba(79, 209, 197, var(--tw-text-opacity));
  content: open-quote;
  vertical-align: -.4em;
  margin-right: .25em;
  font-size: 4em;
  line-height: .1em;
}

blockquote.after-quote:after {
  --tw-text-opacity: 1;
  color: rgba(79, 209, 197, var(--tw-text-opacity));
  content: close-quote;
  vertical-align: -.4em;
  margin-left: .2em;
  font-size: 4em;
  line-height: .1em;
}

.app-grid {
  min-height: 100vh;
  grid-template: "heading"
                 "main" 1fr
                 "footer"
                 / 1fr;
  justify-content: center;
  place-items: center;
  display: grid;
}

.heading {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  height: 100%;
  width: 100%;
  min-height: 20vw;
  background-image: linear-gradient(#667eeab3, #667eeab3), url("header_quakertown.2c871d98.jpg");
  background-position: top;
  background-size: cover;
  background-attachment: local;
  grid-area: heading;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main {
  height: 100%;
  width: 100%;
  grid-area: main;
  grid-template-rows: auto;
  display: grid;
}

.footer {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  height: 100%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(56, 178, 172, var(--tw-text-opacity));
  width: 100%;
  -moz-column-gap: .5rem;
  grid-template: "email"
                 "give"
                 "copy"
                 / 1fr;
  grid-area: footer;
  justify-content: center;
  place-items: center;
  gap: 1.5rem .5rem;
  padding: 3rem 1rem;
  display: grid;
}

.email {
  grid-area: email;
}

.copy {
  grid-area: copy;
}

.give {
  grid-area: give;
}

#google_translate_element {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-bg-opacity: .3;
  border-radius: .25rem;
  margin-top: .5rem;
  margin-left: .5rem;
  padding: .25rem;
  position: absolute;
  top: 0;
  left: 0;
}

.goog-te-gadget * {
  display: inline !important;
}

.goog-te-gadget .goog-te-combo {
  display: block !important;
}

@media (min-width: 768px) {
  .heading {
    background-size: contain;
    background-attachment: fixed;
  }
}

@media (min-width: 1024px) {
  .footer {
    grid-template: "email copy give" 1fr
    / 1fr auto 1fr;
  }
}

.btn {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  padding: .75rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.btn:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.btn:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 178, 172, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 151, 149, var(--tw-bg-opacity));
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.form-checkbox {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-origin: border-box;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  cursor: pointer;
  height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
  vertical-align: middle;
  width: 1rem;
  border-width: 1px;
  border-radius: .25rem;
  flex-shrink: 0;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.form-checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

.form-checkbox:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-checkbox:checked:focus {
  border-color: #0000;
}

.form-description {
  -webkit-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%;
  border-width: 1px;
  border-radius: .375rem;
  padding: .25rem .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-description:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.text-input {
  -webkit-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%;
  border-width: 1px;
  border-radius: .375rem;
  padding: .25rem .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.text-input:invalid:not([value=""]) {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.border-secondary-300 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 230, 217, var(--tw-border-opacity));
}

.rounded {
  border-radius: .25rem;
}

.border {
  border-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 1rem;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

* {
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.text-secondary-400 {
  --tw-text-opacity: 1;
  color: rgba(79, 209, 197, var(--tw-text-opacity));
}

.text-secondary-500, .hover\:text-secondary-500:hover {
  --tw-text-opacity: 1;
  color: rgba(56, 178, 172, var(--tw-text-opacity));
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.w-16 {
  width: 4rem;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .xl\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

/*# sourceMappingURL=index.19fbbb44.css.map */
