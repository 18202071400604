@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;

* {
  font-family: 'Inter', sans-serif;
}

blockquote {
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D";
}

blockquote.before-quote:before {
  @apply text-secondary-400;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote.after-quote:after {
  @apply text-secondary-400;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.2em;
  vertical-align: -0.4em;
}

@tailwind components;

.app-grid {
  @apply min-h-screen grid justify-center items-center;
  grid-template:
    'heading' auto
    'main'    1fr
    'footer'  auto
  / 1fr;
  justify-items: center;
}

.heading {
  @apply w-full h-full flex justify-center items-center bg-cover bg-primary-500;
  grid-area: heading;
  background-image: linear-gradient(rgba(102, 126, 234, 0.7), rgba(102, 126, 234, 0.7)), url('./assets/header_quakertown.jpg');
  background-size: cover;
  background-position: top;
  background-attachment: local;
  min-height: 20vw;
}

.main {
  @apply w-full h-full grid;
  grid-area: main;
  grid-template-rows: auto;
}

.footer {
  @apply
    w-full
    h-full
    bg-gray-100
    grid
    justify-center
    items-center
    gap-y-6
    gap-x-2
    py-12
    px-4
    text-center
    text-secondary-500;
  grid-area: footer;
  justify-items: center;
  grid-template:
    'email' auto
    'give'   auto
    'copy'  auto
  / 1fr;
}

.email {
  grid-area: email;
}

.copy {
  grid-area: copy;
}

.give {
  grid-area: give;
}

#google_translate_element {
  @apply absolute top-0 left-0 mt-2 ml-2 bg-opacity-30 bg-white p-1 rounded;
}

.goog-te-gadget * {
  @apply inline !important;
}

.goog-te-gadget .goog-te-combo {
  @apply block !important;
}

@screen md {
  .heading {
    background-attachment: fixed;
    background-size: contain;
  }
}

@screen lg {
  .footer {
    grid-template:
      'email copy give' 1fr
    / 1fr    auto  1fr;
  }
}

.btn {
  @apply
    inline-flex
    items-center
    justify-center
    px-5
    py-3
    border
    border-transparent
    text-base
    leading-6
    font-medium
    rounded-md
    bg-gray-100
    transition
    duration-150
    ease-in-out;
}

.btn:hover {
  @apply bg-gray-200;
}

.btn:focus {
  @apply outline-none ring;
}

.btn:disabled {
  @apply bg-gray-500 !important;
}

.btn-secondary {
  @apply bg-secondary-500 text-white;
}

.btn-secondary:hover {
  @apply bg-secondary-600;
}

.btn-primary {
  @apply bg-primary-500 text-white;
}

.btn-primary:hover {
  @apply bg-primary-600;
}

.form-checkbox {
  color-adjust: exact;
  background-origin: border-box;
  user-select: none;
  @apply
    cursor-pointer
    rounded
    border
    bg-white
    w-4
    h-4
    flex-shrink-0
    align-middle
    inline-block
    appearance-none
    text-primary-500
    border-gray-300
    transition
    duration-150
    ease-in-out;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  @apply bg-no-repeat border-transparent;
}

.form-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(164,202,254,.45);
  border-color: #a4cafe;
}

.form-checkbox:checked:focus {
  @apply border-transparent;
}

.form-description {
  @apply w-full py-1 px-2 text-base leading-6 appearance-none border border-gray-300 shadow-none bg-white rounded-md;
}

.form-description:focus {
  @apply outline-none ring border-blue-300;
}

.text-input {
  @apply w-full py-1 px-2 text-base leading-6 appearance-none border border-gray-300 shadow-none bg-white rounded-md;
}

.text-input:focus {
  @apply outline-none ring border-blue-300;
}

.text-input:invalid:not([value=""]) {
  @apply border-red-300 ring-red-200;
}

@tailwind utilities;

@responsive {
  .flex-center {
    @apply flex justify-center items-center;
  }
}